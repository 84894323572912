import React from "react"
import Layout from "../components/layout/Layout"
import styles from "../styles/text-section.module.scss"
import { Helmet } from "react-helmet"
import ContactForm from "../components/general/contactForm/kontaktuForma"
import BenefitsSection from "../components/landing/benefits"

export default function Diagnostika() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Modernia įranga atliekama variklio diagnostika</title>
        <meta
          name="description"
          content="Reikalinga variklio diagnostika? Mūsų kvalifikuoti specialistai atlieka variklio diagnostiką, padeda nustatyti ir pašalinti gedimus. Atvykite į mūsų servisą Kaune."
        ></meta>
      </Helmet>
      <section className={`${styles.textSection} container`}>
        <h1>Variklio diagnostika</h1>
        <p>
          Variklio diagnostika padeda nustatyti problemines variklio vietas,
          surasti esamus gedimus ir problemas, kurios gali tapti būsimų gedimų
          priežastimis. Mūsų naudojama įranga ir patyrusių mechanikų resursai
          leis profesionaliai atlikti variklio diagnostiką. Mūsų autoservise
          atliekama kompiuterinė įvairių automobilių modelių diagnostika, kurios
          pagalba specialistai efektyviai suranda problemines vietas bei
          pašalina gedimus.
        </p>
        <p>
          Atlikę kompiuterinę variklio diagnostiką ir suradę variklio gedimus,
          pasiūlome optimaliausius rastų problemų sprendimo būdus. Vadovaujantis
          automobilių gamintojų nurodymais ir technologinėmis instrukcijomis
          aptariame remonto planą bei pateikiame darbų sąmatą.
        </p>
      </section>
      <ContactForm />
      <BenefitsSection />
    </Layout>
  )
}
